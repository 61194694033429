import { FC, c } from 'lib/component-utils'

const Spinner: FC = ({ className }) => {
  return (
    <svg className={c`w-12 h-12 animate-spin-slow ${className}`} viewBox="0 0 48 48">
      <circle
        className="stroke-current path text-primary-400 animate-dash"
        cx="24"
        cy="24"
        r="20"
        fill="none"
        strokeWidth="4"
        strokeLinecap="round"
      ></circle>
    </svg>
  )
}

export default Spinner
