import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next'
import { ParsedUrlQuery } from 'querystring'
import nookies from 'nookies'
import { Mall, User } from 'lib/dto'
import { Themes, Theme } from '../configs/theme'
import { AppProps } from 'next/app'
import { Context } from 'lib/api'
import cookieParser, { CookieSerializeOptions } from 'cookie'
import setCookieParser from 'set-cookie-parser'
import { match } from 'ts-pattern'
import resolveAcceptLanguage from 'resolve-accept-language'

export type NextContext<Q extends ParsedUrlQuery = ParsedUrlQuery> = GetServerSidePropsContext<Q>
export type StaticNextContext<Q extends ParsedUrlQuery = ParsedUrlQuery> = GetStaticPropsContext<Q>

type WrappedGetServerSideProps<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery
> = (contexts: {
  pageContext: GetServerSidePropsContext<Q>
  userContext: Context
}) => Promise<GetServerSidePropsResult<P & PagePropsMessage>>

type WrappedGetStaticProps<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery
> = (contexts: {
  pageContext: GetStaticPropsContext<Q>
  userContext: Context
}) => Promise<GetStaticPropsResult<P>>

type PagePropsMessage = {
  userContext?: {
    changeMall?: string
    allowUnauthorizedUsers?: boolean
  }
}

type UserPropsOptions = {
  allowUnauthorizedUsers?: boolean
  forceUnauthorizedMode?: boolean
}

export type ContextDTO =
  | {
      isAuthorized: true
      currentMall: Mall
      malls: Array<Mall>
      user: User
      theme: Theme
      cookie: string
      host: string
      ip: string
      userAgent: string
    }
  | {
      isAuthorized: false
      currentMall: Mall
      malls: []
      user: null
      theme: Theme
      cookie: null
      host: string
      ip: string
      userAgent: string
    }

export const withStaticProps = <P extends { [key: string]: any }>(
  getStaticProps?: WrappedGetStaticProps<P>,
  theme: Theme = Themes.SELLMONITOR
) => {
  return async (context: StaticNextContext) => {
    const ctx: ContextDTO = {
      cookie: null,
      currentMall: { code: 'wb', description: 'wb', currency: 'RUB' } as Mall,
      host:
        process.env.NODE_ENV === 'development'
          ? `localhost:${process.env.PORT ?? 4000}`
          : match(theme.hostType)
              .with('sellematics', () => 'sellematics.com')
              .with('sellmonitor', () => 'sellmonitor.com')
              .with('sellscreen', () => 'sellscreen.io')
              .with('uzum', () => 'uzum.sellmonitor.com')
              .exhaustive(),
      isAuthorized: false,
      malls: [],
      user: null,
      ip: '',
      userAgent: '',
      theme,
    }

    if (theme.hostType === 'sellscreen') {
      context.locale = 'en'
      ctx.currentMall.currency = 'USD'
    }

    if (theme.hostType === 'sellmonitor') {
      context.locale = 'ru'
      ctx.currentMall.currency = 'RUB'
    }

    if (theme.hostType === 'uzum') {
      context.locale = 'uz'
      ctx.currentMall.currency = 'UZS'
    }

    if (!getStaticProps) {
      return {
        props: { ctx },
      }
    }

    const staticProps = (await getStaticProps({
      userContext: new Context(ctx),
      pageContext: context,
    })) as {
      props: P & { theme?: Theme }
    }

    if (staticProps.props.theme) {
      ctx.theme = staticProps.props.theme
      if (ctx.theme.hostType === 'sellscreen') {
        context.locale = 'en'
        ctx.currentMall.currency = 'USD'
      }
    }

    staticProps.props = { ...staticProps.props, ctx }

    return staticProps as { props: P & AppProps }
  }
}

export const withUserProps = <P extends { [key: string]: any }>(
  getServerSideProps?: WrappedGetServerSideProps<P>,
  options?: UserPropsOptions
) => {
  return async (context: NextContext) => {
    let ctx: ContextDTO

    try {
      ctx = await getContext(context, Boolean(options?.forceUnauthorizedMode))
    } catch (err: any) {
      if (err?.response?.status === 401) {
        return {
          redirect: {
            destination: `/login/?redirect=${context.resolvedUrl}`,
            permanent: false,
          },
        }
      }
      throw err
    }

    const isSubscription = context.resolvedUrl.includes('subscription')

    if (!ctx.isAuthorized && !options?.allowUnauthorizedUsers && !options?.forceUnauthorizedMode) {
      return {
        redirect: {
          destination: `/login/?redirect=${context.resolvedUrl}`,
          permanent: false,
        },
      }
    }

    if (ctx.isAuthorized && !ctx.user.active && !isSubscription) {
      return {
        redirect: {
          destination: '/subscription/',
          permanent: false,
        },
      }
    }

    if (!getServerSideProps) {
      return {
        props: { ctx },
      }
    }

    if (ctx.isAuthorized && !ctx.currentMall) {
      ctx.user.selectedMall = ctx.malls[0]?.code ?? null
      ctx.currentMall = ctx.malls[0] ?? {
        currency: ctx.theme.hostType === 'sellscreen' ? 'USD' : 'RUB',
      }
      if (ctx.user.selectedMall) {
        nookies.set(context, 'mall', ctx.user.selectedMall, {
          maxAge: 365 * 24 * 60 * 60,
          path: '/',
        })
      }
    }

    const { mall } = context.query
    if (mall) {
      const requestedMall = ctx.malls.find((userMall) => userMall.code === (mall as string))
      if (!requestedMall) {
        if (ctx.user) {
          ctx.user.selectedMall = ctx.malls[0].code
        }
        ctx.currentMall = ctx.malls[0]
        return {
          redirect: {
            destination: '/products/list/',
            permanent: false,
          },
        }
      }
      if (requestedMall) {
        ctx.currentMall = requestedMall
      }
    }

    const serverSideProps = (await getServerSideProps({
      userContext: new Context(ctx),
      pageContext: context,
    })) as {
      props: P & PagePropsMessage
    }

    const pageContextAllowUnauthorizedUsers =
      serverSideProps.props?.userContext?.allowUnauthorizedUsers
    if (!ctx.isAuthorized && pageContextAllowUnauthorizedUsers === false) {
      return {
        redirect: {
          destination: `/login/?redirect=${context.resolvedUrl}`,
          permanent: false,
        },
      }
    }

    if (ctx.isAuthorized && serverSideProps.props?.userContext?.changeMall) {
      const newMall = ctx.malls.find(
        (m) => m.code === serverSideProps.props?.userContext?.changeMall
      )
      if (!newMall) {
        return {
          redirect: {
            destination: `/products/list/${ctx.malls[0].code}/`,
            permanent: false,
          },
        }
      }

      ctx.user.selectedMall = serverSideProps.props.userContext.changeMall
      ctx.currentMall = newMall
      nookies.set(context, 'mall', ctx.user.selectedMall, {
        maxAge: 365 * 24 * 60 * 60,
        path: '/',
      })
    }

    serverSideProps.props = { ...serverSideProps.props, ctx }

    return serverSideProps as { props: P & AppProps & PagePropsMessage }
  }
}

export const getContext = async (nctx: NextContext, forceUnauthorizedMode: boolean) => {
  const ctx = {}
  setTheme(ctx as ContextDTO, nctx)
  setLanguage(ctx as ContextDTO, nctx)
  await setBackendContext(ctx as ContextDTO, nctx, forceUnauthorizedMode).catch((err) => {
    throw err
  })
  return ctx as ContextDTO
}

const setLanguage = (ctx: ContextDTO, nctx: NextContext) => {
  const cookies = nookies.get(nctx)

  if (!cookies['lang']) {
    const locale = match(ctx.theme.hostType)
      .with('sellscreen', () =>
        match(
          resolveAcceptLanguage(
            (nctx.req.headers['Accept-Language'] as string) ?? '',
            ['en-US', 'zh-CN'] as const,
            'en-US'
          )
        )
          .with('en-US', () => 'en')
          .with('zh-CN', () => 'zh')
          .exhaustive()
      )
      .with('sellematics', () =>
        match(
          resolveAcceptLanguage(
            (nctx.req.headers['Accept-Language'] as string) ?? '',
            ['en-US', 'ru-RU'] as const,
            'ru-RU'
          )
        )
          .with('en-US', () => 'en')
          .with('ru-RU', () => 'ru')
          .exhaustive()
      )
      .with('uzum', () =>
        match(
          resolveAcceptLanguage(
            (nctx.req.headers['Accept-Language'] as string) ?? '',
            ['uz-UZ', 'ru-RU'] as const,
            'uz-UZ'
          )
        )
          .with('uz-UZ', () => 'uz')
          .with('ru-RU', () => 'ru')
          .exhaustive()
      )
      .otherwise(() => 'ru')
    nookies.set(nctx, 'lang', locale, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
    })
    nctx.locale = locale
    return
  } else {
    nctx.locale = cookies['lang']
  }

  if (nctx.resolvedUrl === '/' || nctx.resolvedUrl === '/about/') {
    if (ctx.theme.hostType === 'sellmonitor') {
      nctx.locale = 'ru'
      return
    }
    if (ctx.theme.hostType === 'uzum' && !['ru', 'uz'].includes(nctx.locale!)) {
      nctx.locale = 'uz'
      return
    }
    if (
      (ctx.theme.hostType === 'sellematics' && !['ru', 'en'].includes(nctx.locale!)) ||
      (ctx.theme.hostType === 'sellscreen' && !['en', 'zh'].includes(nctx.locale!))
    ) {
      nctx.locale = 'en'
      return
    }
  }
}

const setTheme = (ctx: ContextDTO, nctx: NextContext) => {
  ctx.theme = themeFromHostname(nctx.req.headers.host)
  ctx.host = nctx.req.headers.host!
}

export const themeFromHostname = (host: string | null | undefined) => {
  if (host?.includes('uzum')) {
    return Themes.UZUM
  }
  if (host?.includes('sellmonitor')) {
    return Themes.SELLMONITOR
  }
  if (host?.includes('sellscreen')) {
    return Themes.SELLSCREEN
  }
  if (host?.includes('sellematics')) {
    return Themes.SELLEMATICS
  }
  return Themes.DEFAULT
}

const setBackendContext = async (
  ctx: ContextDTO,
  nctx: NextContext,
  forceUnauthorizedMode: boolean
) => {
  ctx.ip = (nctx.req.headers['x-real-ip'] ?? '') as string
  ctx.userAgent = (nctx.req.headers['user-agent'] ?? '') as string

  const cookieHeader = nctx.req.headers.cookie
  ctx.cookie = cookieHeader ?? null

  if (forceUnauthorizedMode) {
    await makeContextUnauthorized(ctx, nctx)
    return
  }

  let userCtx = new Context(ctx)

  await userCtx
    .getCurrentUser()
    .then(([user, cookies]) => {
      ctx.isAuthorized = true
      if (!ctx.cookie) return

      const phpSetCookies = setCookieParser(cookies)
      const clientCookies = cookieParser.parse(ctx.cookie)

      phpSetCookies.forEach((cookie) => {
        nookies.set(nctx, cookie.name, cookie.value, cookie as CookieSerializeOptions)
        clientCookies[cookie.name] = cookie.value
      })

      ctx.cookie = Object.entries(clientCookies)
        .map(([name, value]) => cookieParser.serialize(name, value))
        .join('; ')

      ctx.user = user
    })
    .catch(async (err: any) => {
      if (err?.response?.status === 401) {
        await makeContextUnauthorized(ctx, nctx)
        return
      }
      throw err
    })

  if (ctx.user === null) return

  userCtx = new Context(ctx)

  await userCtx.getMallList().then((malls) => {
    ctx.malls = malls
    ctx.currentMall = ctx.malls.find((m) => m.code === ctx.user.selectedMall)!
  })
}

const makeContextUnauthorized = async (ctx: ContextDTO, nctx: NextContext) => {
  ctx.isAuthorized = false
  ctx.currentMall = { code: 'wb', currency: 'RUB' } as typeof ctx.currentMall
  ctx.malls = []
  ctx.user = null
}
