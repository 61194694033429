import Head from 'next/head'
import Link from 'components/base/Link'
import { FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import Image from 'next/image'
import backgroundImage from '@/public/next/img/landing/background-auth.jpg'

type Props = {
  pageName: string
}

const AuthLayout: FC<Props> = ({ pageName, children }) => {
  const ctx = useUserContext()

  return (
    <>
      <Head>
        <title>{`${pageName} | ${ctx.theme.title}`}</title>
      </Head>
      <div className="relative flex justify-center min-h-screen max-w-[100vw] md:px-12 lg:px-0 overflow-hidden">
        <div className="relative z-10 flex flex-col justify-center flex-1 px-4 pt-16 pb-24 md:flex-none md:px-28">
          <div className="w-full max-w-md mx-auto sm:px-4 md:w-96 md:max-w-sm md:px-0">
            <Link href="/" aria-label="Home" className="flex justify-center">
              <img className="h-6" src={ctx.theme.logoDark} />
            </Link>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthLayout
